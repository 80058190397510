import React from "react";

export interface CreatorContextProps {
  children: React.ReactNode;
  category: string;
}

export const defaultCreatorContext = {
  creator: { isAstro: true },
};

export interface CreatorContextState {
  creator: {
    isAstro: boolean;
  };
}
export const Context = React.createContext<CreatorContextState>(
  defaultCreatorContext
);

export const CreatorContext = ({ children, category }: CreatorContextProps) => {
  return (
    <Context.Provider
      value={{
        creator: {
          isAstro: category === "astro",
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
