import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { CalendarStatus } from "@/features/consultation/domain/models/booking";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import creatorUseCases from "@/features/consultation/domain/use-cases/creator/creator";
import bookingDataSourceImplementation, {
  CalendarProviderResponse,
} from "@/features/consultation/infrastructure/datasources/booking";
import {
  CreatorDTO,
  creatorDataSourceImplementation,
} from "@/features/consultation/infrastructure/datasources/creator/creatorDataSourceImplementation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useCalendarProvidersQuery = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);

  const { data, isLoading, error, isRefetching, refetch } = useQuery({
    queryKey: ["calendars-providers"],
    queryFn: () =>
      booking.getCalendarProviders<AxiosResponse<CalendarProviderResponse>>(),
    select: (response) => {
      return (
        response.data.providers.find(
          (provider) => provider.status === CalendarStatus.SYNCED
        ) || null
      );
    },
    notifyOnChangeProps: ["data"],
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export const useCreatorMeQuery = () => {
  const httpClient = httpImplementation();
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient);
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-me"],
    queryFn: () => useCase.getCreator<AxiosResponse<CreatorDTO>>(),
    select: (data) => data.data,
    notifyOnChangeProps: ["data"],
  });
};
