import { environment } from "@/environments/environment";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: function () {
        return `${environment.cdnUrl}/locales/{{lng}}/{{ns}}.json`;
      },
      crossDomain: true,
    },
    detection: {
      caches: ["cookie"],
      order: ["cookie", "navigator"],
      lookupCookie: "_locale",
      convertDetectedLanguage: (lng) => lng.split("-")[0],
    },
    fallbackLng: "fr",
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    nsSeparator: false,
    keySeparator: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

export default i18n;
