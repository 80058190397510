import { environment } from "@/environments/environment";
import { Auth } from "@aws-amplify/auth";
import axios from "axios";

import { HttpGateway } from "@/features/authentication/infrastructure/services/http";

const httpImplementation: HttpGateway = axios.create({
  baseURL: environment.apiUrl,
});

httpImplementation.interceptors.request.use(
  async (config) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  async (error: any) => {
    return Promise.reject(error);
  }
);

export default httpImplementation;
