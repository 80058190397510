import React from "react";

import {
  POST_CONFIGURATION,
  POST_CONFIRMATION,
  POST_MYM_CLOUD,
  POST_SCHEDULE,
  POST_VISIBILITY,
} from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";
import { Navigate } from "react-router-dom";

import { PostCreationStatus } from "@/features/post/infrastructure/datastores/store/store";
import { useStore } from "@/features/post/presentation/pages/setup/viewModel";

const Setup = React.lazy(
  () => import("@/features/post/presentation/pages/setup")
);

const MymCloud = React.lazy(
  () => import("@/features/post/presentation/pages/mym-cloud")
);

const Visibility = React.lazy(
  () => import("@/features/post/presentation/pages/visibility")
);

const Confirmation = React.lazy(
  () => import("@/features/post/presentation/pages/confirmation")
);
const Schedule = React.lazy(
  () => import("@/features/post/presentation/pages/schedule")
);

const VisibilityElement = () => {
  const { status } = useStore() || {};
  return status == PostCreationStatus.VISIBILITY ||
    status == PostCreationStatus.SCHEDULE ||
    status == PostCreationStatus.CONFIRMATION ? (
    <Visibility />
  ) : (
    <Navigate to={POST_CONFIGURATION} />
  );
};

const ScheduleElement = () => {
  const { status } = useStore() || {};
  return status == PostCreationStatus.SCHEDULE ||
    status == PostCreationStatus.CONFIRMATION ? (
    <Schedule />
  ) : (
    <Navigate to={POST_CONFIGURATION} />
  );
};

const ConfirmationElement = () => {
  const { status } = useStore();
  return status == PostCreationStatus.CONFIRMATION ? (
    <Confirmation />
  ) : (
    <Navigate to={POST_CONFIGURATION} />
  );
};

export const routes: RouteConfig[] = [
  {
    path: POST_CONFIGURATION,
    element: <Setup />,
    isPrivate: true,
  },
  {
    path: POST_MYM_CLOUD,
    element: <MymCloud />,
    isPrivate: true,
  },
  {
    path: POST_VISIBILITY,
    element: <VisibilityElement />,
    isPrivate: true,
  },
  {
    path: POST_SCHEDULE,
    element: <ScheduleElement />,
    isPrivate: true,
  },
  {
    path: POST_CONFIRMATION,
    element: <ConfirmationElement />,
    isPrivate: true,
  },
];
