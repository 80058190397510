import { AuthenticationRepository } from "@/features/authentication/domain/repositories/authentication";

export const authenticationUseCases = (
  repository: AuthenticationRepository
): AuthenticationRepository => ({
  confirmSignIn: async (params: any) => {
    if (!params.code) {
      throw Error("Code is required");
    }

    if (!params.user) {
      throw Error("User is required");
    }

    return await repository.confirmSignIn(params);
  },
  confirmSignUp: async (params: any) => {
    if (!params.code) {
      throw Error("Code is required");
    }

    if (!params.username) {
      throw Error("Username is required");
    }

    return await repository.confirmSignUp(params);
  },
  currentSession: async () => {
    return await repository.currentSession();
  },
  federatedSignIn: async (params: any) => {
    if (!params.provider) {
      throw Error("Provider is required");
    }

    return await repository.federatedSignIn(params);
  },
  forgotPassword: async (params: any) => {
    if (!params.username) {
      throw Error("Username is required");
    }

    return await repository.forgotPassword(params);
  },
  forgotPasswordSubmit: async (params: any) => {
    if (!params.username) {
      throw Error("Username is required");
    }

    if (!params.code) {
      throw Error("Code is required");
    }

    if (!params.password) {
      throw Error("Password is required");
    }

    return await repository.forgotPasswordSubmit(params);
  },
  resendSignUp: async (params: any) => {
    if (!params.username) {
      throw Error("Username is required");
    }

    return await repository.resendSignUp(params);
  },
  signIn: async (params: any) => {
    if (!params.password) {
      throw Error("Password is required");
    }

    if (!params.username) {
      throw Error("Username is required");
    }

    return await repository.signIn(params);
  },
  signUp: async (params: any) => {
    if (!params.password) {
      throw Error("Password is required");
    }

    if (!params.username) {
      throw Error("Username is required");
    }

    return await repository.signUp(params);
  },
});

export default authenticationUseCases;
