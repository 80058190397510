import {
  Calendar,
  CalendarId,
} from "@/features/consultation/domain/models/booking";
import {
  BookingRepository,
  BookingsDatesParams,
  CalendarProviderParams,
  SynchroniseCalendarParams,
} from "@/features/consultation/domain/repositories/booking";
import { HttpGateway } from "@/features/consultation/infrastructure/services/http";

export interface CalendarProviderResponse {
  providers: Array<Calendar>;
}

export interface CalendarProviderAuthResponse {
  authorization_link: string;
}

export type BookingDatesDTO = string[];

export interface BookingDTO {
  id: string;
  creator: {
    id: string;
    username: string;
  };
  prestation: {
    id: string;
    title: string;
    description: string;
  };
  price: {
    value: number;
    currency: string;
  };
  status: string;
  start: string;
  end: string;
  chatId: number;
  user: {
    username: string;
  };
}

export const bookingDataSourceImplementation = (
  gateway: HttpGateway
): BookingRepository => ({
  getCalendarProviders: async <T>() => {
    return gateway.get<T>("/bookings/calendars/providers");
  },
  desynchroniseCalendar: async <T>(calendarId: CalendarId) => {
    return gateway.delete<T>(`/bookings/calendars/${calendarId}`);
  },
  synchroniseCalendar: async <T>(params: SynchroniseCalendarParams) => {
    return gateway.post<T>("/bookings/calendars/connect", {
      code: params.code,
      provider: params.provider,
      redirect_uri: params.redirect_uri,
    });
  },
  getCalendarProviderUrl: async <T>(params: CalendarProviderParams) => {
    return gateway.get<T>(
      `/bookings/calendars/connect?provider=${params.provider}&redirect_uri=${params.redirect_uri}`
    );
  },
  getBookingsDates: async <T>(params: BookingsDatesParams) => {
    return gateway.get<T>(
      `/bookings/dates?startDate=${params.startDate}&endDate=${params.endDate}`
    );
  },
  getBookingsByDate: async <T>(date: string) => {
    return gateway.get<T>(`/bookings/search?date=${date}`);
  },
  deleteBooking: async <T>(bookingId: string) => {
    return gateway.delete<T>(`/bookings/${bookingId}`);
  },
});

export default bookingDataSourceImplementation;
