import { useCallback } from "react";
import { useContext } from "react";

import { AuthContext, type AuthContextProps } from "@/contexts/auth/Auth";
import { environment } from "@/environments/environment";
import { isStandalone } from "@/utils/env";
import { useTranslation } from "react-i18next";

import { Button, Heading, Text, Title } from "@leeloo/core";

import { Illustration } from "@/components/illustration/Illustration";
import { LayoutCenter } from "@/components/layout-center/LayoutCenter";
import { LayoutUnauthenticated } from "@/components/layout-unauthenticated/LayoutUnauthenticated";

import * as styles from "./home.css";

export const Home = () => {
  const { t } = useTranslation();
  const { loading, logout } = useContext(AuthContext) as AuthContextProps;

  const redirect = useCallback(() => {
    return window.location.replace(`${environment.origin}/app/profile`);
  }, []);

  return (
    <LayoutUnauthenticated>
      <LayoutCenter>
        <Heading centered="mobile-and-desktop" className={styles.heading}>
          <Illustration name="warning" />
          <Title as="h3">{t("nothing_here_title")}</Title>
          <Text variant="large">{t("nothing_here_description")}</Text>
        </Heading>
        <Button
          children={t("nothing_here_button")}
          disabled={loading}
          onPress={() => {
            isStandalone ? logout() : redirect();
          }}
        />
      </LayoutCenter>
    </LayoutUnauthenticated>
  );
};

export default Home;
