import { Logo } from "@leeloo/core";

import { ButtonBack } from "@/components/button-back/ButtonBack";

import { SigninLink } from "@/features/authentication/presentation/containers/signin-link/SigninLink";
import { SignupLink } from "@/features/authentication/presentation/containers/signup-link/SignupLink";

import * as styles from "./layout-unauthenticated.css";

export interface LayoutUnauthenticatedProps {
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
  signinLink?: boolean;
  signupLink?: boolean;
}

export const LayoutUnauthenticated = ({
  children,
  sidebar,
  signinLink = false,
  signupLink = false,
  ...props
}: LayoutUnauthenticatedProps) => {
  return (
    <div {...props} className={styles.layout}>
      <header className={styles.header({ desktop: true })}>
        <Logo />
        {signinLink && !signupLink && <SigninLink />}
        {signupLink && !signinLink && <SignupLink />}
      </header>
      <header className={styles.header({ mobile: true })}>
        <ButtonBack className={styles.back} />
        <Logo />
        <div />
      </header>
      <div className={styles.content}>
        {!!sidebar && sidebar}
        <main className={styles.children}>{children}</main>
      </div>
    </div>
  );
};
