import { AuthenticationRepository } from "@/features/authentication/domain/repositories/authentication";
import {
  ConfirmSignInDTO,
  ConfirmSignUpDTO,
  FederatedSignInDTO,
  ForgotPasswordDTO,
  ForgotPasswordSubmitDTO,
  ResendSignUpDTO,
  SignInDTO,
  SignUpDTO,
} from "@/features/authentication/infrastructure/datasources/authentication/authenticationDataSource";
import { AuthGateway } from "@/features/authentication/infrastructure/services/auth";

export const authenticationDataSourceImplementation = (
  gateway: AuthGateway
): AuthenticationRepository => ({
  confirmSignIn: async <T, D = ConfirmSignInDTO>(params: D): Promise<T> => {
    return await gateway.confirmSignIn(params);
  },
  confirmSignUp: async <T, D = ConfirmSignUpDTO>(params: D): Promise<T> => {
    return await gateway.confirmSignUp(params);
  },
  currentSession: async <T>(): Promise<T> => {
    return await gateway.currentSession();
  },
  federatedSignIn: async <T, D = FederatedSignInDTO>(params: D): Promise<T> => {
    return await gateway.federatedSignIn(params);
  },
  forgotPassword: async <T, D = ForgotPasswordDTO>(params: D): Promise<T> => {
    return await gateway.forgotPassword(params);
  },
  forgotPasswordSubmit: async <T, D = ForgotPasswordSubmitDTO>(
    params: D
  ): Promise<T> => {
    return await gateway.forgotPasswordSubmit(params);
  },
  resendSignUp: async <T, D = ResendSignUpDTO>(params: D): Promise<T> => {
    return await gateway.resendSignUp(params);
  },
  signIn: async <T, D = SignInDTO>(params: D): Promise<T> => {
    return await gateway.signIn(params);
  },
  signUp: async <T, D = SignUpDTO>(params: D): Promise<T> => {
    return await gateway.signUp(params);
  },
});
