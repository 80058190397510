import { useMutation, useQuery } from "@tanstack/react-query";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AxiosResponse } from "axios";

import authenticationUseCases from "@/features/authentication/domain/use-cases/authentication";
import creatorUseCases from "@/features/authentication/domain/use-cases/creator";
import referralUseCases from "@/features/authentication/domain/use-cases/referral";
import { authenticationDataSourceImplementation } from "@/features/authentication/infrastructure/datasources/authentication/authenticationDataSourceImplementation";
import {
  CreatorDTO,
  ProfilePictureDTO,
  creatorDataSourceImplementation,
} from "@/features/authentication/infrastructure/datasources/creator/creatorDataSourceImplementation";
import {
  ReferralDTO,
  referralDataSourceImplementation,
} from "@/features/authentication/infrastructure/datasources/referral/referralDataSourceImplementation";
import { authStore } from "@/features/authentication/infrastructure/datastores/authStore";
import { authStoreImplementation } from "@/features/authentication/infrastructure/datastores/authStoreImplementation";
import authImplementation from "@/features/authentication/infrastructure/services/authImplementation";
import httpImplementation from "@/features/authentication/infrastructure/services/httpImplementation";
import { trackingImplementation } from "@/features/authentication/infrastructure/services/trackingImplementation";

export const useAuthStore = () => {
  const store = authStoreImplementation();
  const authStoreDataStore = authStore(store);

  return authStoreDataStore;
};

export const useAuth = () => {
  const authClient = authImplementation;
  const authenticationDataSourceImpl =
    authenticationDataSourceImplementation(authClient);
  const useCase = authenticationUseCases(authenticationDataSourceImpl);

  return {
    currentSession: async () => {
      const session = await useCase.currentSession<CognitoUserSession>();

      return session;
    },
  };
};

export const useCreatorMeQuery = () => {
  const httpClient = httpImplementation;
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient);
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-me"],
    queryFn: () => useCase.getMe<AxiosResponse<CreatorDTO>>(),
    select: (data) => data.data,
    notifyOnChangeProps: ["data"],
  });
};

export const useCreatorProfilePictureQuery = () => {
  const httpClient = httpImplementation;
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient);
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-profile-picture"],
    queryFn: () =>
      useCase.getProfilePicture<AxiosResponse<ProfilePictureDTO>>(),
    select: (data) => data.data,
    notifyOnChangeProps: ["data"],
  });
};

export const useReferralMutation = () => {
  const httpClient = httpImplementation;
  const referralDataSourceImpl = referralDataSourceImplementation(httpClient);
  const useCase = referralUseCases(referralDataSourceImpl);

  return useMutation({
    mutationKey: ["referral"],
    mutationFn: (payload: ReferralDTO) =>
      useCase.postReferral<AxiosResponse<ReferralDTO>>(payload),
    onSuccess: (data: AxiosResponse<ReferralDTO>) => data.data,
  });
};

export const useAuthTracking = () => {
  const trackImpl = trackingImplementation;

  return {
    trackSignUp: (id: string) => {
      trackImpl.identify(id);
    },
  };
};
