import { environment } from "@/environments/environment";

/* eslint-disable @typescript-eslint/ban-ts-comment */
(async () => {
  const script = document.createElement("script");

  script.src = `https://consent.cookiefirst.com/sites/${environment.currentDomain}-${environment.cookieFirstApiKey}/consent.js`;
  script.async = true;

  document.body.appendChild(script);

  script.onload = () => {
    Promise.resolve();
  };

  script.onerror = () => {
    console.error("Cookiefirst error");
  };
})();
