import { StateCreator, StoreMutatorIdentifier, create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { AuthSlice } from "@/features/authentication/infrastructure/datastores/authStore";

type MiddlewaresProps = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<
    T,
    [...Mps, ["zustand/devtools", never], ["zustand/persist", unknown]],
    Mcs
  >
) => StateCreator<
  T,
  Mps,
  [["zustand/devtools", never], ["zustand/persist", T], ...Mcs]
>;

const middlewares = ((f) =>
  devtools(
    persist(f, {
      name: "store",
      onRehydrateStorage: () => (state) => {
        return state;
      },
    })
  )) satisfies MiddlewaresProps;

const createAuthSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (set) => ({
  accountType: "",
  cognitoUser: undefined,
  logged: false,
  session: undefined,
  referral: undefined,
  updateAccountType: (accountType) => set(() => ({ accountType })),
  updateCognitoUser: (cognitoUser) => set(() => ({ cognitoUser })),
  updateLogged: (logged) => set(() => ({ logged })),
  updateReferral: (referral) => set(() => ({ referral })),
  updateSession: (session) => set(() => ({ session })),
});

export const authStoreImplementation = create<AuthSlice>()(
  middlewares((...a) => ({
    ...createAuthSlice(...a),
  }))
);
