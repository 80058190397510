import { CognitoUser } from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";

import { type Referral } from "@/features/authentication/domain/models/referral";

export interface UserAttributes {
  sub?: string;
  email?: string;
  email_verified?: string;
  name?: string;
  updated_at?: string;
}

export interface CognitoUserProps extends CognitoUser {
  attributes?: UserAttributes;
  username?: string;
  challengeParam?: {
    [key: string]: string;
  };
}

interface State {
  accountType: string;
  cognitoUser?: CognitoUserProps;
  logged: boolean;
  session?: CognitoUserSession;
  referral?: Referral;
}

interface Action {
  updateAccountType: (accountType: State["accountType"]) => void;
  updateCognitoUser: (user?: State["cognitoUser"]) => void;
  updateLogged: (accountType: State["logged"]) => void;
  updateReferral: (referral?: State["referral"]) => void;
  updateSession: (accessToken?: State["session"]) => void;
}

export interface AuthSlice extends State, Action {}

export const authStore = (store: AuthSlice) => ({
  accountType: store.accountType,
  cognitoUser: store.cognitoUser,
  logged: store.logged,
  referral: store.referral,
  session: store.session,
  updateAccountType: store.updateAccountType,
  updateCognitoUser: store.updateCognitoUser,
  updateLogged: store.updateLogged,
  updateReferral: store.updateReferral,
  updateSession: store.updateSession,
});
