import { environment } from "@/environments/environment";

const mapRedirectSignIn: Record<string, string> = {
  "/app/catalog/calendar/oauthcallback": window.location.href,
  "/app/catalog/calendar/oauthcallback/": window.location.href,
};

export const amplify = {
  Auth: {
    region: environment.cognitoRegion,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    cookieStorage: {
      domain: environment.amplifyCookieDomain, // localhost or .<domain>.com
      path: "/",
      expires: 365,
      sameSite: "strict",
      secure:
        environment.amplifyCookieDomain !== "localhost" &&
        environment.amplifyCookieDomain !== "dev.creators.mym.fans",
    },
    oauth: mapRedirectSignIn[window.location.pathname]
      ? { redirectSignIn: mapRedirectSignIn[window.location.pathname] }
      : {
          domain: environment.cognitoUserPoolDomain,
          scope: ["phone", "email", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: environment.cognitoRedirectSignin,
          redirectSignOut: environment.cognitoRedirectSignout,
          clientId: environment.cognitoClientId,
          responseType: "code",
        },
  },
};
