import React, { useCallback } from "react";

import type { DataLayerObject } from "@/types/analytics";

const AnalyticsContext = React.createContext({});

export { AnalyticsContext };

export interface AnalyticsContextProps {
  createFunnelEvent: (event: DataLayerObject) => void;
}

export const Provider = ({ ...props }) => {
  const pushToDataLayer = (event: DataLayerObject) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };

  const createFunnelEvent = useCallback((event: DataLayerObject) => {
    return pushToDataLayer(event);
  }, []);

  return <AnalyticsContext.Provider {...props} value={{ createFunnelEvent }} />;
};
