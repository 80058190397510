import { CreatorRepository } from "@/features/consultation/domain/repositories/creator";

export const creatorUseCases = (
  repository: CreatorRepository
): CreatorRepository => ({
  getCreator: async () => {
    try {
      return await repository.getCreator();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getProfilePicture: async () => {
    try {
      return await repository.getProfilePicture();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default creatorUseCases;
