import { Creator } from "@/features/authentication/domain/models/creator";
import { CreatorRepository } from "@/features/authentication/domain/repositories/creator";
import { HttpGateway } from "@/features/authentication/infrastructure/services/http";

export type CreatorDTO = Creator;
export type ProfilePictureDTO = {
  url: string;
  preview: string;
};

export const creatorDataSourceImplementation = (
  gateway: HttpGateway
): CreatorRepository => ({
  getMe: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/creators/me");
    } catch (e) {
      throw Error("Error during getting creator", {
        cause: e,
      });
    }
  },
  getProfilePicture: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/creators/me/profile-picture");
    } catch (e) {
      throw Error("Error during getting creator profile picture", {
        cause: e,
      });
    }
  },
});
