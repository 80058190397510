import { LOGIN } from "@/constants/routes";
import { useTranslation } from "react-i18next";

import { Text } from "@leeloo/core";

import { Link } from "../../../../../components/link/Link";

export const SigninLink = () => {
  const { t } = useTranslation();

  return (
    <Text centered>
      {t("common_already_have_an_account")}&nbsp;
      <Link to={LOGIN}>{t("common_login_button")}</Link>
    </Text>
  );
};
