import { lazy } from "react";

import { PROFILE, PROFILE_EDITION, VIEW_POST } from "@/constants/routes";
import { RouteConfig } from "@/routes/routes";

const Profile = lazy(
  () => import("@/features/profile/presentation/pages/profile/Profile")
);

const ProfileEdition = lazy(
  () =>
    import(
      "@/features/profile/presentation/pages/profile-edition/ProfileEdition"
    )
);

const ViewPost = lazy(
  () => import("@/features/profile/presentation/pages/view-post")
);

export const routes: Array<RouteConfig> = [
  {
    path: PROFILE,
    isPrivate: true,
    element: <Profile />,
  },
  {
    path: PROFILE_EDITION,
    isPrivate: true,
    element: <ProfileEdition />,
  },
  {
    path: VIEW_POST,
    isPrivate: true,
    element: <ViewPost />,
  },
];
