export type MediaName = string;

export type Media = {
  name: string;
  thumbnailUrl: string;
  type: string;
  size: number;
};

export type Price = {
  amount: number | null;
  discountAmount: number | null;
  discountDuration: number | null;
};

export type Public = "my-subscribers" | "my-old-subscribers" | "my-interested";

export type Medias = Media[];

export type PushDate = {
  year: number | null;
  month: number | null;
  day: number | null;
  hour: number | null;
  minute: number | null;
};

export type DisplayedCartridges = {
  priceInformation: boolean;
  pricePromotion: boolean;
};

export interface PushState {
  medias: Medias;
  description: string;
  audience: Array<Public>;
  publishNow: () => boolean;
  price: Price;
  hasDiscount: boolean;
  isPushConfigured: boolean;
  isPushConfirmed: boolean;
  isVisibilityConfigured: boolean;
  isPriceConfigured: boolean;
  isScheduled: boolean;
  publicationDate: PushDate;
  displayedCartridges: DisplayedCartridges;
}

export interface PushAction {
  addMedia: (media: Media) => void;
  deleteMedia: (media: MediaName) => void;
  updateDescription: (description: string) => void;
  addAudience: (target: Public) => void;
  deleteAudience: (target: Public) => void;
  updatePrice: (price: Partial<Price>) => void;
  updateIsPushConfigured: (isPushConfigured: boolean) => void;
  updateIsVisibilityConfigured: (isVisibilityConfigured: boolean) => void;
  updateIsPriceConfigured: (isPriceConfigured: boolean) => void;
  updateIsScheduled: (isScheduled: boolean) => void;
  updatePublicationDate: (publicationDate: PushDate) => void;
  updateDisplayedCartridges: (
    cartridgesStatus: Partial<DisplayedCartridges>
  ) => void;
  updateIsPushConfirmed: (isConfirmed: boolean) => void;
  resetPushStore: () => void;
  updateHasDiscount: (hasDiscount: boolean) => void;
}

export interface PushSlice extends PushState, PushAction {}

export const pushStore = (store: PushSlice) => ({
  medias: store.medias,
  audience: store.audience,
  description: store.description,
  publishNow: store.publishNow,
  isPushConfigured: store.isPushConfigured,
  isPushConfirmed: store.isPushConfirmed,
  isVisibilityConfigured: store.isVisibilityConfigured,
  isPriceConfigured: store.isPriceConfigured,
  hasDiscount: store.hasDiscount,
  updateIsPushConfigured: store.updateIsPushConfigured,
  updateIsPushConfirmed: store.updateIsPushConfirmed,
  updateIsVisibilityConfigured: store.updateIsVisibilityConfigured,
  addMedia: store.addMedia,
  deleteMedia: store.deleteMedia,
  updateDescription: store.updateDescription,
  addAudience: store.addAudience,
  price: store.price,
  deleteAudience: store.deleteAudience,
  updatePrice: store.updatePrice,
  isScheduled: store.isScheduled,
  displayedCartridges: store.displayedCartridges,
  publicationDate: store.publicationDate,
  updateIsScheduled: store.updateIsScheduled,
  updatePublicationDate: store.updatePublicationDate,
  updateIsPriceConfigured: store.updateIsPriceConfigured,
  updateDisplayedCartridges: store.updateDisplayedCartridges,
  updateHasDiscount: store.updateHasDiscount,
  resetPushStore: store.resetPushStore,
});
