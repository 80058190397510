import { Auth } from "@aws-amplify/auth";

import { AuthGateway } from "@/features/authentication/infrastructure/services/auth";

const authImplementation: AuthGateway = {
  confirmSignIn: async (params?: any) => {
    return await Auth.confirmSignIn(params.user, params.code);
  },
  confirmSignUp: async (params?: any) => {
    return await Auth.confirmSignUp(
      params.username,
      params.code,
      params.options
    );
  },
  currentSession: async () => {
    return await Auth.currentSession();
  },
  federatedSignIn: async (params?: any) => {
    return await Auth.federatedSignIn({ provider: params.provider });
  },
  forgotPassword: async (params?: any) => {
    return await Auth.forgotPassword(params.username);
  },
  forgotPasswordSubmit: async (params?: any) => {
    return await Auth.forgotPasswordSubmit(
      params.username,
      params.code,
      params.password,
      {
        password: params.password,
      }
    );
  },
  resendSignUp: async (params?: any) => {
    return await Auth.resendSignUp(params.username);
  },
  signIn: async (params?: any) => {
    return await Auth.signIn(params.username, params.password);
  },
  signUp: async (params?: any) => {
    return await Auth.signUp({
      username: params.username,
      password: params.password,
      clientMetadata: {
        acceptPrivacyPolicy: params.opts.acceptPrivacyPolicy,
        acceptTermsOfService: params.opts.acceptTermsOfService,
        acceptTermsOfUse: params.opts.acceptTermsOfUse,
        password: params.password,
        ...(params.opts.sponsorId && params.opts.sponsorType
          ? {
              sponsorId: params.opts.sponsorId,
              sponsorType: params.opts.sponsorType,
            }
          : ""),
      },
      autoSignIn: {
        enabled: true,
      },
    });
  },
};

export default authImplementation;
