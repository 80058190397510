import { LOGIN_MFA } from "@/constants/routes";
import { CognitoUser } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import authenticationUseCases from "@/features/authentication/domain/use-cases/authentication";
import {
  FederatedSignInDTO,
  SignInDTO,
} from "@/features/authentication/infrastructure/datasources/authentication/authenticationDataSource";
import { authenticationDataSourceImplementation } from "@/features/authentication/infrastructure/datasources/authentication/authenticationDataSourceImplementation";
import { authStore } from "@/features/authentication/infrastructure/datastores/authStore";
import { authStoreImplementation } from "@/features/authentication/infrastructure/datastores/authStoreImplementation";
import authImplementation from "@/features/authentication/infrastructure/services/authImplementation";

export const useAuthStore = () => {
  const store = authStoreImplementation();
  const authStoreDataStore = authStore(store);

  return authStoreDataStore;
};

export const useAuth = () => {
  const authClient = authImplementation;
  const authenticationDataSourceImpl =
    authenticationDataSourceImplementation(authClient);
  const useCase = authenticationUseCases(authenticationDataSourceImpl);
  const { updateCognitoUser, updateLogged } = useAuthStore();
  const navigate = useNavigate();

  return {
    federatedSignIn: async (payload: FederatedSignInDTO) => {
      await useCase.federatedSignIn(payload);
    },
    signIn: async (payload: SignInDTO) => {
      const user = await useCase.signIn<CognitoUser, SignInDTO>(payload);

      updateCognitoUser(user);

      if (user.challengeName === "SMS_MFA") {
        navigate(LOGIN_MFA, {
          state: payload,
        });
      } else {
        updateLogged(true);
      }
      return user;
    },
  };
};
