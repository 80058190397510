export type CalendarId = "google" | "icloud";

export enum CalendarStatus {
  SYNCED = "Synced",
  UNSYNCED = "Unsynced",
}

export enum BookingStatus {
  Incoming = "incoming",
  Done = "done",
  Canceled = "canceled",
}

export interface Calendar {
  provider_id: CalendarId;
  title: string;
  status: CalendarStatus;
  email: string;
}

export interface Booking {
  id: string;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: BookingStatus;
  chatId: string;
  liveId: string;
}
