import React from "react";

import { environment } from "@/environments/environment";
import { mode } from "@/utils/env";
import { captureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: environment.sentryDsn,
  environment: mode,
  release: environment.appVersion,
  integrations: [
    Sentry.breadcrumbsIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [import.meta.env.VITE_CREATOR_BASE_URL],
    }),
    captureConsoleIntegration(),
  ],

  tracesSampleRate: 1.0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
