import { pushStore } from "@/features/push/infrastructure/datastores/pushStore";
import { pushStoreImplementation } from "@/features/push/infrastructure/datastores/pushStoreImplementation";

export const usePushStore = () => {
  const pushStoreImpl = pushStore(pushStoreImplementation());

  return {
    isPriceConfigured: pushStoreImpl.isPriceConfigured,
    isPushConfigured: pushStoreImpl.isPushConfigured,
    isVisibilityConfigured: pushStoreImpl.isVisibilityConfigured,
  };
};
