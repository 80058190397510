import React, { useEffect } from "react";

import { SIGNUP_SOCIAL_NETWORK_CONFIRMATION } from "@/constants/routes";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuthStore } from "@/features/authentication/presentation/pages/login/viewModel";
import { useCreatorMeQuery } from "@/features/authentication/presentation/pages/oauth2-callback/viewModel";

import { Creator } from "@/types/creator";

const UserContext = React.createContext({});

export interface UserContextProps {
  isFetching: boolean;
  currentUser: Creator;
}
export { UserContext };

export const Consumer = UserContext.Consumer;

export const Provider = ({ ...props }) => {
  const { updateReferral } = useAuthStore();
  const { data: currentUser, isFetching } = useCreatorMeQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ambassador = searchParams.get("ambassador") || null;
  const creator = searchParams.get("creator") || null;

  useEffect(() => {
    if (creator) {
      updateReferral({ id: parseInt(creator), userType: "creator" });
    }

    if (ambassador) {
      updateReferral({ id: parseInt(ambassador), userType: "ambassador" });
    }
  }, [ambassador, creator]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (
      !currentUser.privacyPolicyAccepted ||
      !currentUser.termsOfServiceAccepted ||
      !currentUser.termsOfUseAccepted
    ) {
      navigate(SIGNUP_SOCIAL_NETWORK_CONFIRMATION);
    }
  }, [
    currentUser?.certificationStatus,
    currentUser?.privacyPolicyAccepted,
    currentUser?.termsOfServiceAccepted,
    currentUser?.termsOfUseAccepted,
  ]);

  return (
    <UserContext.Provider {...props} value={{ isFetching, currentUser }} />
  );
};
