import { ReferralRepository } from "@/features/authentication/domain/repositories/referral";

import { Referral } from "@/types/creator";

export const referralUseCases = (
  repository: ReferralRepository,
): ReferralRepository => ({
  postReferral: async (referral: Referral) => {
    try {
      if (!referral.id) {
        throw Error("ID is required");
      }

      if (!referral.userType) {
        throw Error("User type is required");
      }

      return await repository.postReferral(referral);
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default referralUseCases;
