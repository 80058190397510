import React, { Suspense, useContext } from "react";

import { LOGOUT } from "@/constants/routes";
import { AuthContext, AuthContextProps } from "@/contexts/auth/Auth";
import { Navigate } from "react-router-dom";

import { Loader } from "@leeloo/core";

import { useCreatorMeQuery } from "@/features/authentication/presentation/pages/oauth2-callback/viewModel";

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { logged } = useContext(AuthContext) as AuthContextProps;
  const { isFetching } = useCreatorMeQuery();

  if (isFetching) {
    return <Loader spin={true} />;
  }

  if (!logged) {
    return <Navigate to={LOGOUT} replace />;
  }

  return <Suspense>{children}</Suspense>;
};
