import { environment } from "@/environments/environment";

import { analytics } from "@/features/authentication/infrastructure/services/trackingImplementation";

const queryString = window.location.search;
const searchParams = new URLSearchParams(queryString);

(() => {
  window.addEventListener("cf_consent_loaded", function (event) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const previouslySavedConsent = event.detail;
    if (!previouslySavedConsent) {
      // consent hasn't been given yet
    } else {
      // previous consent is available
      analytics.load(
        environment.rudderStackKey,
        environment.rudderStackDataplane
      );

      if (searchParams.get("utm_source")) {
        localStorage.setItem(
          "utm_source",
          searchParams.get("utm_source") as string
        );
        analytics.page();
      }
    }
  });

  window.addEventListener("cf_consent", function (event) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.detail.advertising) {
      analytics.load(
        environment.rudderStackKey,
        environment.rudderStackDataplane
      );

      if (searchParams.get("utm_source")) {
        localStorage.setItem(
          "utm_source",
          searchParams.get("utm_source") as string
        );
        analytics.page();
      }
    }
  });
})();
