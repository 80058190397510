import { CalendarId } from "@/features/consultation/domain/models/booking";
import {
  BookingRepository,
  BookingsDatesParams,
  CalendarProviderParams,
  SynchroniseCalendarParams,
} from "@/features/consultation/domain/repositories/booking";

export const bookingUseCases = (repository: BookingRepository) => ({
  getCalendarProviders: <T>() => {
    try {
      return repository.getCalendarProviders<T>();
    } catch (error: any) {
      throw new Error(error);
    }
  },
  desynchroniseCalendar: <T>(calendarId: CalendarId) => {
    try {
      if (!calendarId) {
        throw new Error("Calendar ID is required");
      }

      return repository.desynchroniseCalendar<T>(calendarId);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  getCalendarProviderUrl: <T>(params: CalendarProviderParams) => {
    try {
      if (!params.provider) {
        throw new Error("Calendar ID is required");
      }

      if (!params.redirect_uri) {
        throw new Error("Redirect URI is required");
      }

      return repository.getCalendarProviderUrl<T>(params);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  synchroniseCalendar: <T>(params: SynchroniseCalendarParams) => {
    try {
      if (!params.code) {
        throw new Error("Code is required");
      }

      if (!params.provider) {
        throw new Error("Provider is required");
      }

      return repository.synchroniseCalendar<T>(params);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  getBookingsDates: <T>(params: BookingsDatesParams) => {
    try {
      if (!params.startDate) {
        throw new Error("startDate is required");
      }
      if (!params.endDate) {
        throw new Error("endDate is required");
      }
      return repository.getBookingsDates<T>(params);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  getBookingsByDate: <T>(date: string) => {
    try {
      if (!date) {
        throw new Error("date is required");
      }
      return repository.getBookingsByDate<T>(date);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  deleteBooking: <T>(bookingId: string) => {
    try {
      if (!bookingId) {
        throw new Error("Booking id is required");
      }
      return repository.deleteBooking<T>(bookingId);
    } catch (error: any) {
      throw new Error(error);
    }
  },
});

export default bookingUseCases;
