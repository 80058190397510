import { Creator } from "@/features/consultation/domain/models/creator";
import { CreatorRepository } from "@/features/consultation/domain/repositories/creator";
import { HttpGateway } from "@/features/consultation/infrastructure/services/http";

export type CreatorDTO = Creator;

export const creatorDataSourceImplementation = (
  gateway: HttpGateway
): CreatorRepository => ({
  getCreator: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/creators/me");
    } catch (e) {
      throw Error("Error while fetching creator information", {
        cause: e,
      });
    }
  },
  getProfilePicture: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/creators/me/profile-picture");
    } catch (e) {
      throw Error("Error during getting creator profile picture", {
        cause: e,
      });
    }
  },
});
