import { RudderAnalytics } from "@rudderstack/analytics-js";

import { TrackingService } from "@/features/authentication/infrastructure/services/tracking";

export const analytics = new RudderAnalytics();

export const trackingImplementation: TrackingService = {
  identify: (uuid) => {
    return analytics.identify(uuid);
  },
};
