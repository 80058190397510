import React, { useContext } from "react";

import { HOME } from "@/constants/routes";
import { AuthContext, AuthContextProps } from "@/contexts/auth/Auth";
import { environment } from "@/environments/environment";
import { isStandalone } from "@/utils/env";
import { Navigate } from "react-router-dom";

interface PublicRouteProps {
  children: React.ReactNode;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { logged } = useContext(AuthContext) as AuthContextProps;

  if (logged) {
    if (!isStandalone) {
      window.location.replace(`${environment.origin}/app/profile`);
      return <>{children}</>;
    }

    return <Navigate to={HOME} replace />;
  }

  return <>{children}</>;
};
