import * as yup from "yup";

export const isNumeric = (value: string | undefined) =>
  !!(value && /^\d+$/.test(value));

export const hasAtLeastNChar = (n: number) => (value: string) =>
  value.split("").length >= n;

export const hasUppercaseLetter = (value: string | undefined) =>
  !!(value && /[A-Z]/.test(value));

export const hasSpecialCharacter = (value: string | undefined) =>
  !!(value && /[#!,/&'(§!ç*)$%:.;@#><+]/.test(value));

export const hasNoSpecialCharacter = (value: string | undefined) =>
  !hasSpecialCharacter(value);

export const hasNoAccentedCharacter = (value: string | undefined) =>
  !(value && /[À-ÿ]/.test(value));

export const hasNumber = (value: string | undefined) =>
  !!(value && /\d/.test(value));

export const hasLowercaseLetter = (value: string | undefined) =>
  !!(value && /[a-z]/.test(value));

export const hasSpace = (value: string | undefined) =>
  !!(value && /\s/.test(value));

export const hasNoSpace = (value: string | undefined) => !hasSpace(value);

yup.setLocale({
  mixed: {
    required: "mandatory_field_is_empty_error",
    notType: "mandatory_field_is_empty_error",
  },
});
