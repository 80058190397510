import { CreatorRepository } from "@/features/authentication/domain/repositories/creator";

export const creatorUseCases = (
  repository: CreatorRepository
): CreatorRepository => ({
  getMe: async () => {
    try {
      return await repository.getMe();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
  getProfilePicture: async () => {
    try {
      return await repository.getProfilePicture();
    } catch (e: any) {
      throw Error(e, {
        cause: e,
      });
    }
  },
});

export default creatorUseCases;
