import { lazy } from "react";

import {
  PUSH_CONFIGURATION,
  PUSH_CONFIRMATION,
  PUSH_PRICE,
  PUSH_SCHEDULE,
  PUSH_VISIBILITY,
} from "@/constants/routes";
import { usePushStore } from "@/routes/push/viewModel";
import { RouteConfig } from "@/routes/routes";
import { Navigate } from "react-router-dom";

const Confirmation = lazy(
  () => import("@/features/push/presentation/pages/confirmation"),
);
const Setup = lazy(() => import("@/features/push/presentation/pages/setup"));

const Visibility = lazy(
  () => import("@/features/push/presentation/pages/visibility"),
);

const Schedule = lazy(
  () => import("@/features/push/presentation/pages/schedule"),
);

const Price = lazy(() => import("@/features/push/presentation/pages/price"));

const Recap = lazy(() => import("@/features/push/presentation/pages/recap"));

const VisibilityElement = () => {
  const { isPushConfigured } = usePushStore();

  return isPushConfigured ? (
    <Visibility />
  ) : (
    <Navigate to={PUSH_CONFIGURATION} />
  );
};

const ScheduleElement = () => {
  const { isPushConfigured, isVisibilityConfigured } = usePushStore();

  return isPushConfigured && isVisibilityConfigured ? (
    <Schedule />
  ) : (
    <Navigate to={PUSH_CONFIGURATION} />
  );
};

const ConfirmationElement = () => {
  const { isPushConfigured, isVisibilityConfigured } = usePushStore();

  return isPushConfigured && isVisibilityConfigured ? (
    <Confirmation />
  ) : (
    <Navigate to={PUSH_CONFIGURATION} />
  );
};

const PriceElement = () => {
  const { isPushConfigured, isVisibilityConfigured } = usePushStore();

  return isPushConfigured && isVisibilityConfigured ? (
    <Price />
  ) : (
    <Navigate to="/push/configuration" />
  );
};

export const routes: Array<RouteConfig> = [
  {
    path: PUSH_CONFIGURATION,
    isPrivate: true,
    element: <Setup />,
  },
  {
    path: PUSH_VISIBILITY,
    isPrivate: true,
    element: <VisibilityElement />,
  },
  {
    path: PUSH_SCHEDULE,
    isPrivate: true,
    element: <ScheduleElement />,
  },
  {
    path: PUSH_PRICE,
    isPrivate: true,
    element: <PriceElement />,
  },
  {
    path: PUSH_CONFIRMATION,
    isPrivate: true,
    element: <ConfirmationElement />,
  },
  {
    path: "/push/recap",
    isPrivate: true,
    element: <Recap />,
  },
];
