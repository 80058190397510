import { useTranslation } from "react-i18next";

import { Button, Illustration, Text, Title } from "@leeloo/core";

import * as styles from "./error.css";

export function Error({ onClick, ...props }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <div className={styles.error} {...props}>
      <Illustration name="warning" className={styles.illustration} />
      <Title as="h3" className={styles.title}>
        {t("loader_page_error_title")}
      </Title>
      <Text className={styles.text}>{t("loader_page_error_description")}</Text>
      <Button className={styles.button} onPress={onClick}>
        {t("loader_page_error_button")}
      </Button>
    </div>
  );
}
