import React from "react";

import {
  HOME,
  LOGIN,
  LOGIN_MFA,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_CONFIRMATION,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  SIGNUP,
  SIGNUP_CHOICE,
  SIGNUP_EMAIL,
  SIGNUP_EMAIL_CONFIRMATION,
  SIGNUP_SOCIAL_NETWORK_CONFIRMATION,
} from "@/constants/routes";

import Home from "@/features/authentication/presentation/pages/home";

import type { RouteConfig } from "../routes";

const Login = React.lazy(
  () => import("@/features/authentication/presentation/pages/login")
);
const LoginMFA = React.lazy(
  () => import("@/features/authentication/presentation/pages/login-mfa")
);
const PasswordRecovery = React.lazy(
  () => import("@/features/authentication/presentation/pages/password-recovery")
);
const PasswordRecoveryConfirmation = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/password-recovery-confirmation"
    )
);
const PasswordReset = React.lazy(
  () => import("@/features/authentication/presentation/pages/password-reset")
);
const PasswordResetSuccess = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/password-reset-success"
    )
);
const Signup = React.lazy(
  () => import("@/features/authentication/presentation/pages/signup")
);
const SignupChoice = React.lazy(
  () => import("@/features/authentication/presentation/pages/signup-choice")
);
const SignupEmail = React.lazy(
  () => import("@/features/authentication/presentation/pages/signup-email")
);
const SignupEmailConfirmation = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/signup-email-confirmation"
    )
);
const SignupSocialNetworkConfirmation = React.lazy(
  () =>
    import(
      "@/features/authentication/presentation/pages/signup-social-network-confirmation"
    )
);

export const routes: RouteConfig[] = [
  {
    path: HOME,
    element: <Home />,
    isPrivate: true,
  },
  {
    path: LOGIN,
    element: <Login />,
    isPublic: true,
  },
  {
    path: LOGIN_MFA,
    element: <LoginMFA />,
    isPublic: true,
  },
  {
    path: SIGNUP,
    element: <Signup />,
    isPublic: true,
  },
  {
    path: SIGNUP_CHOICE,
    element: <SignupChoice />,
    isPublic: true,
  },
  {
    path: SIGNUP_EMAIL,
    element: <SignupEmail />,
    isPublic: true,
  },
  {
    path: SIGNUP_EMAIL_CONFIRMATION,
    element: <SignupEmailConfirmation />,
    isPublic: true,
  },
  {
    path: SIGNUP_SOCIAL_NETWORK_CONFIRMATION,
    element: <SignupSocialNetworkConfirmation />,
    isPrivate: true,
  },
  {
    path: PASSWORD_RECOVERY,
    element: <PasswordRecovery />,
    isPublic: true,
  },
  {
    path: PASSWORD_RECOVERY_CONFIRMATION,
    element: <PasswordRecoveryConfirmation />,
    isPublic: true,
  },
  {
    path: PASSWORD_RESET,
    element: <PasswordReset />,
    isPublic: true,
  },
  {
    path: PASSWORD_RESET_SUCCESS,
    element: <PasswordResetSuccess />,
    isPublic: true,
  },
];
