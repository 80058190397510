import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  ButtonBack as LeelooButtonBack,
  ButtonBackProps as LeelooButtonBackProps,
} from "@leeloo/core";

import * as styles from "./button-back.css";

export const ButtonBack = ({
  onClick,
  ...props
}: Omit<LeelooButtonBackProps, "link">) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    onClick?.(e);
    navigate(-1);
  };

  return (
    <LeelooButtonBack
      {...props}
      onClick={handleClick}
      className={clsx(styles.element, props.className)}
      link={Link}
    />
  );
};
