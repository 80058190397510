import { Referral } from "@/features/authentication/domain/models/referral";
import { ReferralRepository } from "@/features/authentication/domain/repositories/referral";
import { HttpGateway } from "@/features/authentication/infrastructure/services/http";

export type ReferralDTO = Referral;

export const referralDataSourceImplementation = (
  gateway: HttpGateway
): ReferralRepository => ({
  postReferral: async <T, ReferralDTO>(referral: ReferralDTO): Promise<T> => {
    try {
      return await gateway.post("/creators/referral", referral);
    } catch (e) {
      throw Error("Error during posting creator referral", {
        cause: e,
      });
    }
  },
});
