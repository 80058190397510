import clsx from "clsx";

import * as styles from "./layout-center.css";

export interface LayoutCenterProps {
  children?: React.ReactNode;
  className?: string;
}

export const LayoutCenter = ({ ...props }: LayoutCenterProps) => (
  <section {...props} className={clsx(styles.element, props.className)} />
);
