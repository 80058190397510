import clsx from "clsx";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";

import * as styles from "./link.css";

export const Link = ({
  className,
  ...props
}: ReactRouterLinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  return (
    <ReactRouterLink {...props} className={clsx(styles.element, className)} />
  );
};
